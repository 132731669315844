import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    total_carrito:0,
    carrito:[],
    item_carrito:null,
    despacho:[],
    item_despacho:null,
    factura:[],
    item_factura:null,
    onecode:'',
  },
  mutations: {
    addCarrito(state){
      state.carrito = [state.item_carrito,...state.carrito];
      state.total_carrito++
    },
    removeItemCarrito(state){
      const i = state.carrito.findIndex( datos => datos.code === state.onecode );
      state.carrito.splice(i, 1);
      state.total_carrito--
    },
    removeAllCarrito(state){
      state.carrito = state.carrito.filter(function(dat) {
        return dat.code !== state.onecode
      });
      state.total_carrito=state.carrito.length
    },
    addDespacho(state){
      state.despacho = [];
      state.despacho = [state.item_despacho,...state.despacho];
    },
    removeDespacho(state){
      state.despacho = [];
      state.item_despacho = null
    },
    addFactura(state){
      state.factura = [];
      state.factura = [state.item_factura,...state.factura];
    },
    removeFactura(state){
      state.factura = [];
      state.item_factura = null
    }
  },
  actions: {
    addCarritoAction(context){
      context.commit('addCarrito')
    },
    removeItemCarritoAction(context){
      context.commit('removeItemCarrito')
    },
    addDespachoAction(context){
      context.commit('addDespacho')
    },
    removeDespachoAction(context){
      context.commit('removeDespacho')
    },
    addFacturaAction(context){
      context.commit('addFactura')
    },
    removeFacturaAction(context){
      context.commit('removeFactura')
    },
    removeAllCarritoAction(context){
      context.commit('removeAllCarrito')
    }
  },
  modules: {
  }
})
