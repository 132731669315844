<template>
    <div>
        <v-container>
          <v-card class="mt-5">
          <v-data-table :headers="titulo" :items="productos" hide-default-footer class="elevation-1 col-color">
            <template v-slot:item.src="{ item }">
                <div class="mt-2">
                    <v-img :src="item.src" max-height="100" max-width="100"></v-img>
                </div>
            </template>              
            <template v-slot:item.price="{ item }">
                $ {{new Intl.NumberFormat("de-DE").format(parseFloat(item.price))}}
            </template>              
            <template v-slot:item.quanty="{ item }">
                <v-row>
                    <v-col cols="4" md="4" class="text-center">
                        <v-icon @click="menos(item)" class="success--text">remove_circle</v-icon>
                    </v-col>
                    <v-col cols="4" md="4" class="text-center">
                        <div v-if="parseInt(item.stock)>=parseInt(item.quanty)">
                            <span>{{new Intl.NumberFormat("de-DE").format(parseFloat(item.quanty))}}</span>
                        </div>
                        <div v-else>
                            <span class="red--text">{{new Intl.NumberFormat("de-DE").format(parseFloat(item.quanty))}}</span>
                        </div>
                        <!-- <v-text-field class='text-caption' v-model="item.quanty" disabled filled outlined dense></v-text-field> -->
                    </v-col>
                    <v-col cols="4" md="4" class="text-center">
                        <v-icon @click="mas(item)" class="success--text">add_circle</v-icon>
                    </v-col>
                </v-row>
            </template>              
            <template v-slot:item.subtotal="{ item }">
                $ {{new Intl.NumberFormat("de-DE").format(parseFloat(item.subtotal))}}
            </template>              
            <template v-slot:item.accion="{ item }">
                <v-icon @click="borrar(item)" class="error--text">cancel</v-icon>
            </template>              
          </v-data-table>
          <v-card-actions>
              <v-container>
                <v-row>
                    <v-col cols="12" md="4" class="mt-2">
                        <v-row>
                            <h6>* Para retiro en tienda, no agregar dirección</h6>
                        </v-row>
                        <v-row>
                            <v-btn @click="abrirDialogo()" outlined rounded small>
                            <v-img src="@/assets/camion.png" max-height="20" max-width="30"></v-img>
                            <h5 v-if="condespacho!='si'" class="ml-2">Agregar dirección</h5>
                            <h5 v-else class="ml-2">Borrar dirección</h5>
                            <v-icon :color="black">arrow_drop_down</v-icon>
                            </v-btn>
                        </v-row>
                        <v-row class="mt-5">
                            <h6 v-if="condespacho=='si'">Tienes 1 dirección agregada</h6>
                            <h6 v-else>Tienes 0 dirección agregada</h6>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-2">
                        <v-row>
                            <h6>* Para Boleta, no agregar datos de Facturación</h6>
                        </v-row>
                        <v-row>
                            <v-btn @click="abrirFactura()" outlined rounded small>
                            <v-img src="@/assets/factura.png" max-height="20" max-width="30"></v-img>
                            <h5 v-if="confactura!='si'" class="ml-2">Agregar Facturación</h5>
                            <h5 v-else class="ml-2">Borrar Facturación</h5>
                            <v-icon :color="black">arrow_drop_down</v-icon>
                            </v-btn>
                        </v-row>
                        <v-row class="mt-5">
                            <h6 v-if="confactura=='si'">Pedido con Factura</h6>
                            <h6 v-else>Pedido con Boleta</h6>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-5">
                        <v-row>
                            <div style="width:90%" class="text-end">
                                <strong>TOTAL:&nbsp;&nbsp;${{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}</strong>
                                <v-btn @click="validarCarrito()" class="ml-3 mr-3" :color="black" small dark rounded>Pagar</v-btn>
                            </div>
                        </v-row>
                        <!-- <v-row>
                            <div style="width:100%" class="text-end">
                            <img src="@/assets/logo-web-pay-plus.png"/>
                            </div>
                        </v-row>                          -->
                    </v-col>
                </v-row>
              </v-container>
          </v-card-actions>
          </v-card>
          <div style="height:65px"></div>
        </v-container>
        <!-- formulario de despacho -->
        <v-dialog v-model="dialogo_despacho" persistent max-width="350">
            <v-card max-width="350">
                <v-card-title>
                    Datos del envío
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogo_despacho=false" :color="black">cancel</v-icon>
                </v-card-title>
                <v-card-subtitle><h5 class="error--text">* Todos los datos son obligatorios</h5></v-card-subtitle>
                <v-card-text>
                    <v-text-field class="text-caption" v-model="rut" label="Rut" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="contacto" label="Nombre Contacto" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="email" label="Email" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="fono" label="Telefono" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="direccion" label="Direccion" outlined dense rounded></v-text-field>
                    <v-select v-if="regiones.length!=0" class="mt-n3 text-caption" @change="leer_ciudad(region)" v-model="region" :items="regiones" label="Region" outlined dense rounded></v-select>
                    <v-select v-else class="mt-n3 text-caption" label="Region" outlined dense rounded disabled></v-select>
                    <v-select v-if="ciudades.length!=0" class="mt-n3 text-caption" @change="leer_comuna(region,ciudad)" v-model="ciudad" :items="ciudades" label="Provincia" outlined dense rounded></v-select>
                    <v-select v-else class="mt-n3 text-caption" label="Provincia" outlined dense rounded disabled></v-select>
                    <v-select v-if="comunas.length!=0" class="mt-n3 text-caption" v-model="comuna" :items="comunas" label="Ciudad/Comuna" outlined dense rounded></v-select>
                    <v-select v-else class="mt-n3 text-caption" label="Ciudad/Comuna" outlined dense rounded disabled></v-select>
                    <!-- <h5> * El costo del despacho es de ${{new Intl.NumberFormat("de-DE").format(parseFloat(valor_envio))}}</h5> -->
                    <h5>* Despacho Talca $2.000</h5>
                    <h5>* Despacho otras ciudades y regiones $4.990</h5>
                    <v-btn v-if="condespacho!='si'" @click="AgregarDespacho()" class="mt-3" :color="black" dark block rounded small>Agregar dirección</v-btn>
                    <v-btn v-else @click="borrarDireccion()" class="mt-3" :color="black" dark block rounded small>Borrar dirección</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- resumen del pago -->
        <v-dialog v-model="dialogo_pago" persistent max-width="350">
            <v-card max-width="350">
                <v-card-title>
                    Resumen de Pago
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogo_pago=false" :color="black">cancel</v-icon>
                </v-card-title>
                <v-card-subtitle>
                    <h5 v-if="condespacho=='si'">Con despacho a domicilio</h5>
                    <div v-else>
                        <h5>Con retiro en tienda</h5>
                        <!-- <h5 class="mt-n1">Direccion de Retiro</h5> -->
                    </div>
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" md="6" class="text-end">
                            <h4>Total en productos:</h4>
                        </v-col>
                        <v-col cols="6" md="6" class="text-end">
                            <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}</h3>
                        </v-col>
                        <v-col cols="6" md="6" class="mt-n5 text-end">
                            <h4>Total de envio:</h4>        
                        </v-col>
                        <v-col cols="6" md="6" class="mt-n5 text-end">
                            <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(envio))}}</h3>                            
                        </v-col>
                        <v-col cols="6" md="6" class="mt-n5 text-end">
                           <h4>Total a Pagar:</h4>                    
                        </v-col>
                        <v-col cols="6" md="6" class="mt-n5 text-end">
                            <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(pago_webpay))}}</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h4>Pago seguro con:</h4>
                            <div style="width:100%" class="text-center">
                                <img src="@/assets/logo-web-pay-plus.png"/>
                            </div>
                        </v-col>
                        <v-col cols="12" md="12">
                            <FORM METHOD="POST" :ACTION="url_webpay" target='_self'>
                                <INPUT type="hidden" NAME="TBK_TOKEN" :VALUE="tK_webpay"/>
                                <v-btn v-if="btn_webpay==0"  color="grey" dark block rounded small>
                                    <v-progress-circular size="20" width="2" indeterminate color="white"></v-progress-circular>
                                </v-btn>
                                <v-btn v-if="btn_webpay==1" color="grey" dark block rounded small>none</v-btn>
                                <v-btn v-if="btn_webpay==2" type="submit" :color="black" dark block rounded small>Pagar</v-btn>
                            </FORM>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- formulario de facturacion -->
        <v-dialog v-model="dialogo_factura" persistent max-width="350">
            <v-card max-width="350">
                <v-card-title>
                    Datos del Facturación
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogo_factura=false" :color="black">cancel</v-icon>
                </v-card-title>
                <v-card-subtitle><h5 class="error--text">* Todos los datos son obligatorios</h5></v-card-subtitle>
                <v-card-text>
                    <v-text-field class="text-caption" v-model="rut_factura" label="Rut" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="razon_factura" label="Razon Social" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="giro_factura" label="Giro" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="email_factura" label="Email" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="fono_factura" label="Telefono" outlined dense rounded></v-text-field>
                    <v-text-field class="mt-n3 text-caption" v-model="direccion_factura" label="Direccion" outlined dense rounded></v-text-field>
                    <v-select v-if="regiones_factura.length!=0" class="mt-n3 text-caption" @change="leer_ciudad_factura(region_factura)" v-model="region_factura" :items="regiones" label="Region" outlined dense rounded></v-select>
                    <v-select v-else class="mt-n3 text-caption" label="Region" outlined dense rounded disabled></v-select>
                    <v-select v-if="ciudades_factura.length!=0" class="mt-n3 text-caption" @change="leer_comuna_factura(region_factura,ciudad_factura)" v-model="ciudad_factura" :items="ciudades_factura" label="Provincia" outlined dense rounded></v-select>
                    <v-select v-else class="mt-n3 text-caption" label="Provincia" outlined dense rounded disabled></v-select>
                    <v-select v-if="comunas_factura.length!=0" class="mt-n3 text-caption" v-model="comuna_factura" :items="comunas_factura" label="Ciudad/Comuna" outlined dense rounded></v-select>
                    <v-select v-else class="mt-n3 text-caption" label="Ciudad/Comuna" outlined dense rounded disabled></v-select>
                    <v-btn v-if="confactura!='si'" @click="AgregarFactura()" class="mt-3" :color="black" dark block rounded small>Agregar Facturación</v-btn>
                    <v-btn v-else @click="borrarFactura()" class="mt-3" :color="black" dark block rounded small>Borrar Facturación</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snack" timeout="2000" color="red" shaped>
            <h4>No es posible crear pedido</h4>
        </v-snackbar>
    </div>
</template>
<style scoped>
/*.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
     background-color: lightyellow;
} */
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import axios from 'axios';
export default {
    name:'Carrito',
    props:['contrato'],
    data: () => ({
        dorado:"#e1c655",
        black:"#0a2f42",
        snack:false,
        titulo:[
          { text: '', align: 'start', sortable: false, value: 'src', width:'10%' },
          { text: 'Producto', align: 'start', sortable: false, value: 'name', width:'30%' },
          { text: 'Variante', align: 'start', sortable: false, value: 'variant', width:'20%' },
          { text: 'Precio', align: 'start', sortable: false, value: 'price', width:'10%' },
          { text: 'Cantidad', align: 'center', sortable: false, value: 'quanty', width:'15%' },
          { text: 'Subtotal', align: 'end', sortable: false, value: 'subtotal', width:'10%' },
          { text: '', align: 'center', sortable: false, value: 'accion', width:'5%' },
        ],
        productos: [],
        total:0,
        envio:0,
        valor_envio:0,
        dialogo_despacho:false,
        rut:'',
        contacto:'',
        email:'',
        fono:'',
        region:'',
        direccion:'',
        comuna:'',
        ciudad:'',
        pais:'Chile',
        regiones:[],
        ciudades:[],
        comunas:[],
        regiones_factura:[],
        ciudades_factura:[],
        comunas_factura:[],
        condespacho:'no',
        dialogo_pago:false,
        url_webpay:'',
        tK_webpay:'',
        pago_webpay:0,
        btn_webpay:0,
        orden:[],
        tienda:'99', //sitio web
        storeLocal:null,
        //formulario factura
        dialogo_factura:false,
        rut_factura:'',
        razon_factura:'',
        giro_factura:'',
        email_factura:'',
        fono_factura:'',
        region_factura:'',
        direccion_factura:'',
        comuna_factura:'',
        ciudad_factura:'',
        confactura:'no',


    }),
    async mounted(){
        if(this.$store.state.carrito.length!=0){
            this.$store.state.carrito=this.$store.state.carrito.reverse()
        };
        //console.log(this.$store.state.carrito);
        await this.process_carrito();
        await this.process_despacho();
        await this.process_factura();
        await this.leer_region();
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.snack=false},2000)
        },
        async leer_region(){
            try {
                const res = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/v1/leer/regiones/');
                const datos=res.data;
                console.log(datos);
                if(datos.length!=0){
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.regiones.push(element.Locates)
                        this.regiones_factura.push(element.Locates)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async leer_ciudad(locate){
            this.ciudades=[];
            this.comunas=[];
            try {
                const res = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/v1/leer/ciudades/' + locate);
                const datos=res.data;
                console.log(datos);
                if(datos.length!=0){
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.ciudades.push(element.Cityes)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async leer_comuna(locate,city){
            this.comunas=[];
            try {
                const res = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/v1/leer/comunas/' + locate + '/' + city);
                const datos=res.data;
                console.log(datos);
                if(datos.length!=0){
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.comunas.push(element.States)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async leer_ciudad_factura(locate){
            this.ciudades_factura=[];
            this.comunas_factura=[];
            try {
                const res = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/v1/leer/ciudades/' + locate);
                const datos=res.data;
                console.log(datos);
                if(datos.length!=0){
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.ciudades_factura.push(element.Cityes)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async leer_comuna_factura(locate,city){
            this.comunas_factura=[];
            try {
                const res = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/v1/leer/comunas/' + locate + '/' + city);
                const datos=res.data;
                console.log(datos);
                if(datos.length!=0){
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.comunas_factura.push(element.States)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async process_carrito(){
            //obtener los sku unicos del array
            var arraysku=[];
            this.$store.state.carrito.forEach(element => {
                arraysku.push(element.code)
            });
            if(arraysku.length!=0){
                arraysku = [...new Set(arraysku)]
                //sumamos las cantidades y subtotal
                var sumaTotal=0;
                arraysku.forEach(element => {
                    const filtro=this.$store.state.carrito.filter( res => res.code==element);
                    if(filtro.length!=0){
                        var sumaCantidad=0;
                        var sumaSubtotal=0;
                        filtro.forEach(elemento => {
                            sumaCantidad++;
                            sumaSubtotal=sumaSubtotal+parseFloat(elemento.price);
                        });
                        sumaTotal=sumaTotal+sumaSubtotal
                        //agregamos el arrayfinal
                        this.productos.push({
                            code:filtro[0].code,
                            src:filtro[0].src,
                            name:filtro[0].name,
                            variant:filtro[0].variant,
                            price:filtro[0].price,
                            quanty:sumaCantidad,
                            subtotal:sumaSubtotal,
                            store:filtro[0].store,
                            stock:filtro[0].stock
                        })
                    }
                });
                //asignamos el total
                this.total=sumaTotal
            }
        },
        menos(item){
            if(item.quanty!=0){
                item.quanty=parseFloat(item.quanty)-1;
                item.subtotal=parseFloat(item.subtotal)-parseFloat(item.price);
                this.total=this.total-parseFloat(item.price)
                this.descontarStore(item)
            }
        },
        async descontarStore(item){
            this.$store.state.onecode = item.code;
            this.$store.dispatch('removeItemCarritoAction');
        },
        mas(item){
            item.quanty=parseFloat(item.quanty)+1;
            item.subtotal=parseFloat(item.subtotal)+parseFloat(item.price);
            this.total=this.total+parseFloat(item.price)
            this.sumarStore(item)
        },
        sumarStore(item){
            //agregar al store
            this.$store.state.item_carrito = item;
            this.$store.dispatch('addCarritoAction');
        },
        async borrar(item){
            if(parseFloat(item.quanty)!=0){
                //eliminamos del store
                this.$store.state.onecode = item.code;
                this.$store.dispatch('removeAllCarritoAction');
                //actualizamos totales
                item.subtotal=parseFloat(item.subtotal)-(parseFloat(item.price)*parseFloat(item.quanty));
                this.total=this.total-(parseFloat(item.price)*parseFloat(item.quanty));
                item.quanty=0
            }
            //eliminamos lo visual
            this.productos = this.productos.filter(function(dat) {
                return dat.code !== item.code
            });
        },
        async process_despacho(){
            const item = this.$store.state.despacho;
            if (item.length!=0){
                item.forEach(element => {
                    this.rut=element.rut;
                    this.contacto=element.contacto;
                    this.email=element.email;
                    this.fono=element.fono;
                    this.direccion=element.direccion;
                    this.region=element.region;
                    this.comuna=element.comuna;
                    this.ciudad=element.ciudad;
                    this.pais=element.pais;
                    this.condespacho='si';
                    this.envio=this.valor_envio
                });
            } else {
                this.rut='';
                this.contacto='';
                this.email='';
                this.fono='';
                this.direccion='';
                this.region='';
                this.comuna='';
                this.ciudad='';                               
                this.condespacho='no';
                this.envio=0;
                this.valor_envio=0
            }
        },
        abrirDialogo(){
            this.process_despacho()
            this.dialogo_despacho=true
        },
        AgregarDespacho(){
            if(this.rut.trim()!='' &&
                this.contacto.trim()!='' &&
                this.email.trim()!='' &&
                this.fono.trim()!='' &&
                this.direccion.trim()!='' &&
                this.region.trim()!='' &&
                this.comuna.trim()!='' &&
                this.ciudad.trim()!=''){
                const item =
                    {
                        rut:this.rut,
                        contacto:this.contacto,
                        email:this.email,
                        fono:this.fono,
                        direccion:this.direccion,
                        region:this.region,
                        comuna:this.comuna,
                        ciudad:this.ciudad,
                        pais:this.pais
                    }                
                this.$store.state.item_despacho = item;
                this.$store.dispatch('addDespachoAction');
                //console.log('store', this.$store.state.despacho);
                this.condespacho='si';
                if(this.comuna=='Talca'){
                    this.valor_envio=2000;
                } else {
                    this.valor_envio=4990;
                };
                this.envio=this.valor_envio;
                this.dialogo_despacho=false
            } else {
                this.condespacho='no';
                this.envio=0
            }
        },
        borrarDireccion(){
            this.valor_envio=0;
            this.$store.dispatch('removeDespachoAction');
            this.process_despacho()
        },
        async process_factura(){
            const item = this.$store.state.factura;
            if (item.length!=0){
                item.forEach(element => {
                    this.rut_factura=element.rut_factura;
                    this.razon_factura=element.razon_factura;
                    this.giro_factura=element.giro_factura;
                    this.email_factura=element.email_factura;
                    this.fono_factura=element.fono_factura;
                    this.direccion_factura=element.direccion_factura;
                    this.region_factura=element.region_factura;
                    this.comuna_factura=element.comuna_factura;
                    this.ciudad_factura=element.ciudad_factura;
                    this.pais=element.pais;
                    this.confactura='si';
                });
            } else {
                this.rut_factura='';
                this.razon_factura='';
                this.giro_factura='';
                this.email_factura='';
                this.fono_factura='';
                this.direccion_factura='';
                this.region_factura='';
                this.comuna_factura='';
                this.ciudad_factura='';                               
                this.confactura='no';
            }
        },
        abrirFactura(){
            this.process_factura()
            this.dialogo_factura=true
        },
        AgregarFactura(){
            if(this.rut_factura.trim()!='' &&
                this.razon_factura.trim()!='' &&
                this.giro_factura.trim()!='' &&
                this.email_factura.trim()!='' &&
                this.fono_factura.trim()!='' &&
                this.direccion_factura.trim()!='' &&
                this.region_factura.trim()!='' &&
                this.comuna_factura.trim()!='' &&
                this.ciudad_factura.trim()!=''){
                const item =
                    {
                        rut_factura:this.rut_factura,
                        razon_factura:this.razon_factura,
                        giro_factura:this.giro_factura,
                        email_factura:this.email_factura,
                        fono_factura:this.fono_factura,
                        direccion_factura:this.direccion_factura,
                        region_factura:this.region_factura,
                        comuna_factura:this.comuna_factura,
                        ciudad_factura:this.ciudad_factura,
                        pais:this.pais
                    }                
                this.$store.state.item_factura = item;
                this.$store.dispatch('addFacturaAction');
                //console.log(this.$store.state.despacho);
                this.confactura='si';
                this.dialogo_factura=false
            } else {
                this.confactura='no';
            }
        },
        borrarFactura(){
            this.$store.dispatch('removeFacturaAction');
            this.process_factura()
        },
        async validarCarrito(){
            console.log(this.productos);
            let valida=true;
            if(this.productos.length!=0 && parseFloat(this.total)!=0){
                this.productos.forEach(element => {
                   if(parseInt(element.quanty) > parseInt(element.stock)){
                    valida=false
                   } 
                });
                if (valida==true){
                    this.pagar()
                } else {
                    console.log('Algunos productos superan el stock');
                    this.snack=true;
                    this.showSnack();
                }
            } else {
                console.log('No existen productos en el carrito');
            }
        },
        async pagar(){
            try {
                if(parseFloat(this.total)!=0){
                    this.pago_webpay=parseFloat(this.total)+parseFloat(this.envio);
                    this.dialogo_pago=true;
                    this.btn_webpay=0;
                    const numero = await this.crearOrden();
                    if(numero!=0){
                        const save = await this.saveOrdenWeb(numero);
                        if (save=='ok'){
                            const params={
                                'contrato': this.contrato,
                                'orden': 'OC-'+ numero,
                                'session': 'SS-'+ numero,
                                'monto': parseFloat(this.pago_webpay)
                            };
                            //const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/test/create/',params);
                            const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/create/',params);
                            const datos=response.data;
                            //console.log(datos);
                            let datosOK='si';
                            const columnas=Object.keys(datos);
                            for (let i = 0; i < columnas.length; i++) {
                                const columna = columnas[i];
                                if(columna=='error_message'){
                                    datosOK='no'
                                }
                            }
                            if(datosOK=='no'){
                                console.log('Transbank no autorizado');
                                this.snack=true;
                                this.showSnack();
                                this.btn_webpay=1;
                            } else {
                                if(datos.token!='no'){

                                    this.url_webpay=datos.url;
                                    this.tK_webpay=datos.token;
                                    //console.log(this.url_webpay,this.tK_webpay);
                                    //grabar token de transaccion
                                    const params1={
                                        'token':this.tK_webpay,
                                        'orden': numero,
                                        'monto': parseFloat(this.pago_webpay)
                                    };
                                    const response1 = await axios.put('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/token/save/'+this.contrato,params1);
                                    const datos1=response1.data;
                                    if(datos1=='si'){
                                        this.btn_webpay=2;
                                        //guardamos el token en memoria
                                        localStorage.removeItem('tokenCompra');
                                        localStorage.setItem('tokenCompra',this.tK_webpay);
                                    }                                
                                } else {
                                    console.log('Transbank sin token');
                                    this.snack=true;
                                    this.showSnack();
                                    this.btn_webpay=1;
                                }
                            }        
                        } else {
                            console.log('Orden no procesada correctamente');
                            this.snack=true;
                            this.showSnack();
                            this.btn_webpay=1;
                        }
                    } else { 
                        console.log('No es posible crear una orden');
                        this.snack=true;
                        this.showSnack();
                        this.btn_webpay=1;
                    }
                }                
            } catch (error) {
                console.log(error)
            }
        },
        async crearOrden(){
            try {
                //creamos numero de orden
                const timestamp = Date.now();
                const numero=this.tienda+''+timestamp.toString();
                //const numero='991687804395869'
                //guardamos el numero
                const params={ 'contrato': this.contrato, 'numero': numero, 'total': parseFloat(this.pago_webpay) };
                const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/create/ordenweb/',params);
                const datos=response.data;
                if(datos=='si'){ return numero } 
                else { return 0 }                
            } catch (error) {
                return 0
            }
        },
        async saveOrdenWeb(numero){
            try {
                var reg=0;
                for (let index = 0; index < this.productos.length; index++) {
                    const element = this.productos[index];
                    const params={ 
                        'contrato': this.contrato, 
                        'orden': numero, 
                        'sku': element.code, 
                        'producto': element.name,
                        'variante': element.variant,
                        'cantidad': element.quanty, 
                        'precio': element.price,
                        'bodega':element.store
                    };
                    const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/save/ordenweb/',params);
                    const datos=response.data;
                    if(datos=='si'){ reg++ } 
                };
                if(reg!=0){
                    if(this.confactura=='si'){
                        const params={ 
                            'contrato': this.contrato, 
                            'orden': numero,
                            'rut': this.rut_factura,
                            'razon': this.razon_factura, 
                            'giro': this.giro_factura, 
                            'email': this.email_factura,
                            'fono': this.fono_factura,
                            'direccion': this.direccion_factura, 
                            'region': this.region_factura, 
                            'ciudad': this.ciudad_factura, 
                            'comuna': this.comuna_factura,
                            'pais': this.pais
                        };
                        const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/save/facturaweb/',params);
                        const datos=response.data;
                        if(datos=='si'){
                            console.log('Factura ok')
                        }
                    }
                    if(this.condespacho=='si'){
                        const params={ 
                            'contrato': this.contrato, 
                            'orden': numero,
                            'rut': this.rut,
                            'contacto': this.contacto, 
                            'email': this.email,
                            'fono': this.fono,
                            'direccion': this.direccion, 
                            'region': this.region, 
                            'ciudad': this.ciudad, 
                            'comuna': this.comuna,
                            'pais': this.pais,
                            'costo':this.valor_envio
                        };
                        const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/save/despacho/ordenweb/',params);
                        const datos=response.data;
                        if(datos=='si'){ 
                            console.log('Despacho ok')
                        }
                    } 
                    return 'ok'
                } else { return 'no' }               
            } catch (error) {
                return 'error'
            }
        }

    }
}
</script>