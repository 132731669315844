<template>
  <div>
    <div v-if="carga_pagina==false">
        <div class="contenedor">
          <div class="hijo">
           <v-img src="@/assets/Logo.jpg" max-height="100" max-width="274"></v-img>
           <v-progress-linear class="mt-5" indeterminate color="orange"></v-progress-linear>
          </div>
        </div>    
    </div>
    <div v-else>
      <div class="hidden-sm-and-down"> <!--full screen --> 
        <div id="head-left">
          <v-row class="mt-2 ml-1">
            <v-col cols="12" md="3" style="background-color:#fff">
              <v-img src="@/assets/Logo.jpg" max-height="100" max-width="274"></v-img>              
            </v-col>
            <v-col id="head-right" cols="12" md="9">
              <v-row class="white--text">
                <v-col cols="1" md="4"></v-col>
                <v-col cols="11" md="4">
                  <v-icon small color="white">location_on</v-icon>
                  <span class="ml-2 text-caption">7 Oriente #665, Talca (entre 5 y 6 sur)</span>
                  <br>
                  <!-- <v-icon small color="white">location_on</v-icon>
                  <span class="ml-2 text-caption">Sucursal: Bulnes 6161A L-19, San Bernardo</span>
                  <br>
                  <v-icon small color="white">location_on</v-icon>
                  <span class="ml-2 text-caption">Sucursal: Urmeneta 595, San Bernardo</span>
                  <br> -->
                  <v-icon small color="white">phone</v-icon>
                  <span class="ml-2 text-caption">(+56) 9 9136 7776 - (+56) 9 7978 6025</span>
                  <br>
                  <v-icon small color="white">email</v-icon>
                  <span class="ml-2 text-caption">contacto@maxel-lubricantes.cl</span>
                </v-col>
                <v-col cols="11" md="4" class="text-center">
                  <span>Siguenos en redes sociales</span>
                  <br>
                    <a href="https://www.tiktok.com/@maxel_lubricantes_spa?_t=8j5HzrxSqLl&_r=1"><img src="@/assets/tictoc_white.png"></a>
                    <a class="ml-2" href="https://www.facebook.com/profile.php?id=100075693196674&mibextid=LQQJ4d"><img src="@/assets/facebook_white.png"></a>
                    <a class="ml-2" href="https://www.instagram.com/maxel_lubricantes?igsh=OXg5bHdmYjBnb211&utm_source=qr"><img src="@/assets/instagram_white.png"></a>
                  <br>
                </v-col>
              </v-row>        
            </v-col>
          </v-row>
          <!-- menu -->
          <v-bottom-navigation class="mt-2" :color="dorado" v-model="value" :background-color="black" dark>
            <v-btn @click="selecMenu('Inicio','all')">
              <span>Home</span>
              <v-icon>home</v-icon>
            </v-btn>
            <v-menu rounded="b-lg" offset-y max-width="600" transition="scale-transition">
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" v-on="on">
                  <span>Categorias</span>
                  <v-icon>dialpad</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-row class="mt-2">
                    <v-col class="mt-n4" cols="4" md="4" v-for="(item, id) in categories" :key="id">
                      <v-btn @click="selecMenu('Body',item.category)" text small rounded>
                        <strong class="text-caption #0a2f42--text">{{ item.category }}</strong>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- <v-list dense>
                <v-list-item v-for="(item, id) in categories" :key="id">
                 <v-list-item-title @click="selecMenu('Body',item.category)"><strong class="text-body-2 grey--text">{{ item.category }}</strong></v-list-item-title>
                </v-list-item>
              </v-list> -->
            </v-menu>
            <v-btn @click="selecMenu('Carrito','all')">
              <span>Compras</span>
              <v-badge v-if="$store.state.total_carrito>0" color="green" :content="$store.state.total_carrito" offset-x="5" offset-y="15">
                 <v-icon>shopping_cart</v-icon>
              </v-badge>
              <v-badge v-else color="green" content="0" offset-x="5" offset-y="15">
                 <v-icon>shopping_cart</v-icon>
              </v-badge>
            </v-btn>
          </v-bottom-navigation>
        </div>
        <div style="position:absolute;top:180px;width:100%;z-index:0">
          <component v-bind:is="componente" :contrato="contrato" :productos="productos" :categoria="categoria"></component>
          <v-footer :color="black" dark>
            <v-row class="mt-1">
              <v-col cols="12" md="12" class="text-center">
                  <span class="text-caption">&copy; {{ new Date().getFullYear() }}</span>
                  <br>
                  <span class="text-caption">BodegaNet &amp; STI tecnología spa.</span>
                  <br>
                  <span class="text-caption">Todos los derechos reservados.</span>
              </v-col>
            </v-row>
          </v-footer>
        </div>
      </div>
      <div class="hidden-sm-and-up"> <!--mobile screen-->
        <div id="head-left">
          <div style="background-color:#0a2f42;width:100%;height:5px"></div>
          <div style="background-color:#e1c655;width:100%;height:5px"></div>
          <v-row class="mt-2 ml-1">
            <v-col cols="12" md="8" class="d-flex flex-column align-center">
              <v-img src="@/assets/Logo.jpg" max-height="100" max-width="274"></v-img>
            </v-col>
          </v-row>
          <v-row class="mt-5">
          <v-bottom-navigation :color="dorado" v-model="value" :background-color="black" dark>
            <v-btn @click="selecMenu('Inicio','all')">
              <span>Home</span>
              <v-icon>home</v-icon>
            </v-btn>
            <v-menu rounded="b-lg" offset-y max-height="300">
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" v-on="on">
                  <span>Categorias</span>
                  <v-icon>dialpad</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, id) in categories" :key="id">
                 <v-list-item-title @click="selecMenu('Body',item.category)"><strong class="#0a2f42--text">{{ item.category }}</strong></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn @click="selecMenu('Carrito','all')">
              <span>Compras</span>
              <v-badge v-if="$store.state.total_carrito>0" color="green" :content="$store.state.total_carrito" offset-x="5" offset-y="15">
                 <v-icon>shopping_cart</v-icon>
              </v-badge>
              <v-badge v-else color="green" content="0" offset-x="5" offset-y="15">
                 <v-icon>shopping_cart</v-icon>
              </v-badge>
            </v-btn>
          </v-bottom-navigation>
          </v-row>
        </div>
        <div style="position:absolute;top:190px;width:100%;z-index:0">
          <div>
            <component v-bind:is="componente" :contrato="contrato" :productos="productos" :categoria="categoria"></component>
          </div>
          <v-footer :color="black" dark>
            <v-container>
            <v-row class="mt-1">
              <v-col cols="1" md="4"></v-col>           
              <v-col cols="11" md="4" class="text-start">
                <v-icon small color="white">location_on</v-icon>
                  <span class="ml-2 text-caption">7 Oriente #665, Talca (entre 5 y 6 sur)</span>
                  <br>
                  <!-- <v-icon small color="white">location_on</v-icon>
                  <span class="ml-2 text-caption">Sucursal: Bulnes 6161A L-19, San Bernardo</span>
                  <br>
                  <v-icon small color="white">location_on</v-icon>
                  <span class="ml-2 text-caption">Sucursal: Urmeneta 595, San Bernardo</span>
                  <br> -->
                  <v-icon small color="white">phone</v-icon>
                  <span class="ml-2 text-caption">(+56) 9 9136 7776 - (+56) 9 7978 6025</span>
                  <br>
                  <v-icon small color="white">email</v-icon>
                  <span class="ml-2 text-caption">contacto@maxel-lubricantes.cl</span>
              </v-col>
              <v-col cols="1" md="4"></v-col>
              <v-col cols="12" md="6" class="text-center">
                <span>Siguenos en redes sociales</span>
                <br>
                  <a href="https://www.tiktok.com/@maxel_lubricantes_spa?_t=8j5HzrxSqLl&_r=1"><img src="@/assets/tictoc_white.png"></a>
                  <a class="ml-2" href="https://www.facebook.com/profile.php?id=100075693196674&mibextid=LQQJ4d"><img src="@/assets/facebook_white.png"></a>
                  <a class="ml-2" href="https://www.instagram.com/maxel_lubricantes?igsh=OXg5bHdmYjBnb211&utm_source=qr"><img src="@/assets/instagram_white.png"></a>
                <br>
                <span class="text-caption">BodegaNet &amp; STI tecnología spa.</span>
                <br>
                <span class="text-caption">&copy; {{ new Date().getFullYear() }} Todos los derechos reservados.</span>
              </v-col>
            </v-row>    
          </v-container>    
          </v-footer>
        </div>
        <v-footer :color="dorado" fixed></v-footer>
      </div>
    </div>
  </div>
</template>
<style scoped>
#head-left {
position:fixed;
width:100%;
z-index:1;
background-color:#fff;
}
#head-right{
/* background-image: url("https://www.pwastore.cl/imgs/triangulo.png"); */
background-image: url("../assets/triangulo.png");
background-color:#0a2f42
}
#texto{
  display: inline-block;
  vertical-align: top;
  margin-left:10px;
}
.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hijo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::-webkit-scrollbar{
  width: 7px;
  height: 8px;
  /* display: none; */
}
/* ::-webkit-scrollbar-track {
  background: black;
} */
::-webkit-scrollbar-thumb {
  background-color: grey;    /* color of the scroll thumb */
  border-radius: 30px;       /* roundness of the scroll thumb */
}
</style>
<script>
import axios from 'axios'
import Body from '@/components/Body'
import Inicio from '@/components/Inicio'
import Carrito from '@/components/Carrito'
export default {
  name: 'Home',
  components: {Inicio, Carrito, Body},
  data: () => ({
    //dorado:'#efb810',
    dorado:"#e1c655",
    black:"#0a2f42",
    carga_pagina:false,
    //contrato:'INV-1004-12345', //test
    contrato:'INV-1012-20490',
    tipo:0,
    componente:'',
    value:0,
    productos:[],
    //Familia_Mascotas = 1 ; Familia_Limpieza = 2
    categories:[],
    categoria:'',
    columnas:1
  }),
  async mounted(){
    await this.redirect();
    await this.categorys();
    await this.products();
    this.categoria='all';
    this.componente='Inicio';
    this.carga_pagina=true
    //console.log('transbak: '+localStorage.getItem('transbank'));
  },
  methods:{
    redirect(){
      const valor = window.location.search;
      console.log('parametros: '+valor);
      const urlParams = new URLSearchParams(valor);
      if(urlParams.has('token_ws')){
        const tkn = urlParams.get('token_ws');
        this.$router.push({name:'Pagos',params:{tk:tkn}}) 
      }else {
        console.log('home')
      }
    },
    selecMenu(compone,cate){
      //console.log(compone,cate);
      this.componente=null;
      this.componente=compone;
      this.categoria=cate;
      // if (tipo==0){ this.componente='Inicio' }
      // if (tipo==11){ this.componente='Perros' }
      // if (tipo==12){ this.componente='Gatos' }
      // if (tipo==13){ this.componente='Exoticos' }
      // if (tipo==14){ this.componente='Accesorios' }
      // if (tipo==2){ this.componente='Limpieza' }
      // if (tipo==3){ this.componente='Carrito' }
    },
    async categorys(){
      try {
          let i=0;
          this.columnas=1;
          const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/categorias/siteweb/'+this.contrato);
          const datos=response.data;
          //console.log('categorias:',datos);
          if (datos[0].existe=='si'){
            datos.forEach(element => {
              i++;
              if(i==10){ i=0; this.columnas++ };
              this.categories.push({
                id: element.ID, 
                category: element.Nombre.toUpperCase(),
                columna: this.columnas
              })
            });
            //console.log('categorias',this.categories);
          }
      } catch (error) {
          console.log(error);
      }
    },
    async products(){
      try {
          const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/siteweb/'+this.contrato);
          const datos=response.data;
          //console.log('productos:',datos);
          if (datos[0].existe=='si'){
              this.productos=datos
          }
      } catch (error) {
          console.log(error);
      }
    },
  }
}
</script>
