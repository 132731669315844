<template>
    <div>
        <div class="hidden-sm-and-down"> <!--full screen -->
            <v-carousel class="mt-4" cycle>
                <v-carousel-item v-for="(item,i) in slides" :key="i" >
                    <v-img :src="item.src"></v-img>
                </v-carousel-item>
            </v-carousel>            
        </div>
        <div class="hidden-sm-and-up"> <!--mobile screen-->
            <v-carousel class="mt-4" cycle height="120" hide-delimiter-background show-arrows-on-hover>
                <v-carousel-item v-for="(item,i) in slides" :key="i">
                        <v-img :src="item.src"></v-img>
                </v-carousel-item>
            </v-carousel>        
        </div>
        <v-container>
            <h2 class="mt-5 mb-2">Productos en Promoción</h2>
            <v-row class="mt-2">
                <v-col v-for="(item,i) in promocion" :key="i" cols="12" md="3" class="d-flex flex-column align-center">
                    <v-card width="250" rounded>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <div class="d-flex flex-column align-center">
                                    <v-img :src="item.imagenes[0]" width="170" height="150"></v-img>
                                    </div>
                                    <h6 class="grey--text">SKU {{item.sku}}</h6>
                                    <h5 class="grey--text">{{item.producto}}</h5>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <h4 class="grey--text">{{item.marca}}</h4>
                                    <h6 class="grey--text">{{item.caracteristica}}</h6>
                                    <h6 class="grey--text">Stock {{item.stock}}</h6>
                                </v-col>
                                <v-col v-if="item.campania=='si'" cols="12" md="6" class="mt-5 text-end">
                                    <h5 class="grey--text">Antes</h5>
                                    <h5 class="text-decoration-line-through">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.noprecio))}}</h5>
                                </v-col>
                            </v-row>
                            <v-divider class="mt-2 mb-4"></v-divider>
                            <v-row>
                                <v-col cols="6" md="6">
                                    <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</h3>
                                </v-col>
                                <v-col cols="6" md="6" class="text-center">
                                    <v-btn @click="AgregarCarrito(item)" small rounded :color="black" dark class="text-caption">
                                        <h5>agregar</h5>
                                        <v-icon class="ml-2" small>shopping_cart</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        <v-container>
        <v-card class="mt-5" width="100%" :color="black" dark>
            <v-card-title>BENEFICIOS</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <h3>Nos dedicamos a la venta y asesoría técnica.</h3>
                    </v-col>
                    <v-divider vertical color="white"></v-divider>
                    <v-col cols="12" md="4">
                        <h3>Tenemos la distribución de grandes marcas reconocidas internacionalmente.</h3>
                    </v-col>
                    <v-divider vertical color="white"></v-divider>
                    <v-col cols="12" md="4">
                        <h3>Nos capacitamos constantemente para ayudarte a elegir el producto adecuado para tu vehiculo.</h3>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions></v-card-actions>
        </v-card>
        </v-container>
        <h2 class="mt-5 mb-2">Productos Destacados</h2>
        <v-row>
            <v-col v-for="(item,i) in destacados" :key="i" cols="12" md="3" class="d-flex flex-column align-center">
                <v-card width="250" rounded>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <div class="d-flex flex-column align-center">
                                <v-img :src="item.imagenes[0]" width="170" height="150"></v-img>
                                </div>
                                <h6 class="grey--text">SKU {{item.sku}}</h6>
                                <h5 class="grey--text">{{item.producto}}</h5>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h4 class="grey--text">{{item.marca}}</h4>
                                <h6 class="grey--text">{{item.caracteristica}}</h6>
                                <h6 class="grey--text">Stock {{item.stock}}</h6>
                            </v-col>
                            <v-col v-if="item.campania=='si'" cols="12" md="6" class="mt-5 text-end">
                                <h5 class="grey--text">Antes</h5>
                                <h5 class="text-decoration-line-through">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.noprecio))}}</h5>
                            </v-col>
                            <v-col cols="12" md="12" class="mt-n10 text-end">
                                <h6 class="grey--text">STOCK {{item.stock}}</h6>
                            </v-col>                                
                        </v-row>
                        <v-divider class="mt-2 mb-4"></v-divider>
                        <v-row>
                            <v-col cols="6" md="6">
                                <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</h3>
                            </v-col>
                            <v-col cols="6" md="6" class="text-center">
                                <v-btn @click="AgregarCarrito(item)" small rounded :color="black" dark class="text-caption">
                                    <h5>agregar</h5>
                                    <v-icon class="ml-2" small>shopping_cart</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="mt-10 mb-2">Multi - Marcas</h2>
        <v-row>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca1.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca2.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca3.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca4.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca5.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca6.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca7.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca8.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca9.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca10.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca11.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca12.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca13.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca14.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca15.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca16.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                            <v-img src='@/assets/Marca17.jpg' max-height="100" max-width="100"></v-img>
                        </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                    <v-img src='@/assets/Marca18.jpg' max-height="100" max-width="100"></v-img>
                </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                    <v-img src='@/assets/Marca19.jpg' max-height="100" max-width="100"></v-img>
                </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                    <v-img src='@/assets/Marca20.jpg' max-height="100" max-width="100"></v-img>
                </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                    <v-img src='@/assets/Marca21.jpg' max-height="100" max-width="100"></v-img>
                </div>
            </v-col>
            <v-col cols="6" md="2">
                <div class="d-flex flex-column align-center">
                    <v-img src='@/assets/Marca22.jpg' max-height="100" max-width="100"></v-img>
                </div>
            </v-col>
        </v-row>

            <!-- <v-row>
                <v-col cols="6" md="2" v-for="(item,i) in marcas" :key="i">
                    <v-card class="mx-auto" rounded>
                        <v-card-text>
                            <div class="d-flex flex-column align-center">
                                <v-img :src='item.src' height="100" width="75"></v-img>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row> -->
        </v-container>
        <v-snackbar v-model="snack" timeout="2000" color="orange" shaped>
            <h4 class="brown--text">Producto agregado</h4>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    name:'Inicio',
    props:['contrato','productos'],
    data: () => ({
        dorado:"#e1c655",
        black:"#0a2f42",
        snack:false,
        slides: [
        //{src: 'https://www.maxel-lubricantes.cl/sliders/slide1.jpg'},
        {src: 'https://www.maxel-lubricantes.cl/sliders/slide2.jpg'},
        {src: 'https://www.maxel-lubricantes.cl/sliders/slide3.jpg'},
        ],
        destacados: [],
        promocion: [],
        marcas: [
        {src: 'Marca1.jpg'},
        ],
    }),
    async mounted(){
        await this.process_products();
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.snack=false},2000)
        },        
        async process_products(){
            if(this.productos.lenght!=0 && this.contrato.trim()!=''){
                this.productos.forEach(element => {
                    var images=element.Imagenes.split(',');
                    var arrayprice=[];
                    if(element.Detalle=='si'){ arrayprice.push(parseFloat(element.PrecioLocal)) };
                    if(element.Mayor=='si'){ arrayprice.push(parseFloat(element.PrecioMayor)) };
                    if(element.Oferta=='si'){ arrayprice.push(parseFloat(element.PrecioOferta)) };
                    var price=0;
                    if(element.Detalle=='si'){ 
                        price=element.PrecioLocal 
                    } else { 
                        if(element.Mayor=='si'){ 
                            price=element.PrecioMayor 
                        } else { 
                            if(element.Oferta=='si'){
                                price=element.PrecioOferta
                            }
                        } 
                    };
                    const maxprice=Math.max(...arrayprice);
                    const minprice=Math.min(...arrayprice);
                    if(price!=0){
                        // Producto Destacado
                        if(element.Destacado=='si'){
                            if(element.Campania!='si'){
                                this.destacados.push({
                                    sku:element.Sku,
                                    producto:element.Nombre,
                                    marca:element.Marca,
                                    caracteristica:element.Caracteristica,
                                    descripcion:element.Descripcion,
                                    precio:maxprice,
                                    campania:'no',
                                    noprecio:0,
                                    imagenBase:images[0],
                                    imagenes:images,
                                    stock:element.Total,
                                    bodega:element.Bodega
                                });
                            } else {
                                this.destacados.push({
                                    sku:element.Sku,
                                    producto:element.Nombre,
                                    marca:element.Marca,
                                    caracteristica:element.Caracteristica,
                                    descripcion:element.Descripcion,
                                    precio:minprice,
                                    campania:'si',
                                    noprecio:maxprice,
                                    imagenBase:images[0],
                                    imagenes:images,
                                    stock:element.Total,
                                    bodega:element.Bodega
                                });
                            }
                            // this.destacados.push({
                            //     sku:element.Sku,
                            //     producto:element.Nombre,
                            //     marca:element.Marca,
                            //     caracteristica:element.Caracteristica,
                            //     descripcion:element.Descripcion,
                            //     precio:price,
                            //     imagenes:images
                            // })
                        }
                        // producto en Promocion
                        if(element.Promocion=='si'){
                            if(element.Campania!='si'){
                                this.promocion.push({
                                    sku:element.Sku,
                                    producto:element.Nombre,
                                    marca:element.Marca,
                                    caracteristica:element.Caracteristica,
                                    descripcion:element.Descripcion,
                                    precio:maxprice,
                                    campania:'no',
                                    noprecio:0,
                                    imagenBase:images[0],
                                    imagenes:images,
                                    stock:element.Total,
                                    bodega:element.Bodega
                                });
                            } else {
                                this.promocion.push({
                                    sku:element.Sku,
                                    producto:element.Nombre,
                                    marca:element.Marca,
                                    caracteristica:element.Caracteristica,
                                    descripcion:element.Descripcion,
                                    precio:minprice,
                                    campania:'si',
                                    noprecio:maxprice,
                                    imagenBase:images[0],
                                    imagenes:images,
                                    stock:element.Total,
                                    bodega:element.Bodega
                                });
                            }
                            // this.promocion.push({
                            //     sku:element.Sku,
                            //     producto:element.Nombre,
                            //     marca:element.Marca,
                            //     caracteristica:element.Caracteristica,
                            //     descripcion:element.Descripcion,
                            //     precio:price,
                            //     imagenes:images
                            // })
                        }
                    }
                });
            } else {
                console.log('sin datos')
            }
        },
        AgregarCarrito(item){
            var arrayCarrito={
                code:item.sku,
                src:item.imagenes[0],
                name:item.producto,
                variant:item.caracteristica,
                price:item.precio,
                quanty:1,
                store:item.bodega,
                stock:item.stock
            }
            this.$store.state.item_carrito = arrayCarrito;
            this.$store.dispatch('addCarritoAction');
            //console.log(this.$store.state.carrito);
            this.snack=true;
            this.showSnack();
        }
    }
}
</script>